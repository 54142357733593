import React from "react"
import HeaderSub from "../../../components/header_sub"
import LayoutOuter from "../../../components/layout_outer"
import LayoutInner from "../../../components/layout_inner"
import BlogEntry from "../../../components/blog_entry"

export default function GeneralAvailability() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="py-16">
          <HeaderSub
            title="André Thiele"
            subtitle="Announcing General Availability"
          />
          <div className="pt-16">
            <BlogEntry
              title="Announcing General Availability"
              preview="We're finally stable! We decided to remove some features from the beta that were causing delays in development or were not requested by our customers. This means that you can no longer back-up your visit history, however we're working on resolving this issue as quickly as possible and will probably allow CSV exports in the future. The focus lies in stabilizing the current experience and finding other more important features that we will work on. Thank you!"
              author="André Thiele"
            />
          </div>
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
